<template>
  <div style="float:left;width:100%;">
    <Refresh
      ref="refresh"
      :productionMain="productionMain"
      :reType="type"
    ></Refresh>
    <van-row class="main" style="background-color:#fff">
      <van-col span="14" style="border:1px solid #d9d4d4;border-left:0px">
        <van-field
          v-model="textOrderNumber"
          clearable
          placeholder="请输入订单号或快递单号"
        />
      </van-col>
      <van-col
        class="text"
        span="5"
        style="border:1px solid #d9d4d4;border-left:0px"
        @click="scanOrder"
      >
        执行
      </van-col>
      <van-col
        id="wxsys"
        class="text"
        span="5"
        style="border:1px solid #d9d4d4;border-left:0px;border-right:0px"
        @click="reset"
      >
        重置
      </van-col>
    </van-row>
    <van-row style="margin-top:0.8rem">
      <van-col
        span="18"
        style="text-align:left;font-size:0.9rem;color:rgb(135 135 135)"
      >
        <span
          v-for="(item, index) in labels.split(';')"
          :key="index"
          style="margin-left: 10px"
        >
          <el-tag
            color="rgb(238, 15, 15)"
            v-if="item != ''"
            class="tag"
            effect="dark"
            size="mini"
            style="border-color: rgb(238, 15, 15)"
          >
            {{ item }}
          </el-tag>
        </span></van-col
      >
      <van-col span="5" style="text-align:right;font-size:0.9rem;color: red;">
      </van-col>
      <van-col
        span="5"
        style="text-align:center;font-size:0.9rem;color:rgb(135 135 135)"
        >订单工价</van-col
      >
      <van-col span="18" style="text-align:right;font-size:0.9rem;color: red;">
        {{ !scanTips ? "" : scanTips }}
        {{ !actualAmount ? "" : actualAmount }}</van-col
      >
      <van-col
        span="5"
        style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135);"
        >工序信息</van-col
      >
      <van-col
        span="18"
        style="text-align:right;margin-top:0.5rem;font-size:0.9rem;"
      >
        {{ !processInfo ? "" : processInfo }}</van-col
      >
      <van-col
        span="5"
        style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135)"
        >订单编号</van-col
      >
      <van-col
        span="18"
        style="text-align:right;margin-top:0.5rem;font-size:0.9rem;"
      >
        {{ !orderNumber ? "" : orderNumber }}</van-col
      >
      <van-col
        span="5"
        style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135)"
        >卖家备注</van-col
      >
      <van-col
        span="18"
        style="text-align:right;margin-top:0.5rem;font-size:0.9rem;word-wrap:break-word; word-break:break-all;"
        >{{ !sellRemark ? "" : sellRemark }}</van-col
      >
      <van-col
        span="5"
        style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135)"
        >客户名称</van-col
      >
      <van-col
        span="18"
        style="text-align:right;margin-top:0.5rem;font-size:0.9rem"
      >
        {{ !custName ? "" : custName }}</van-col
      >
      <van-col
        span="5"
        style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135)"
        >买家信息</van-col
      >
      <van-col
        span="18"
        style="text-align:right;margin-top:0.5rem;font-size:0.9rem"
      >
        {{ !customerName ? "" : customerName }}</van-col
      >
      <van-col
        span="5"
        style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135)"
        >下单日期</van-col
      >
      <van-col
        span="18"
        style="text-align:right;margin-top:0.5rem;font-size:0.9rem"
      >
        {{ !orderDate ? "" : orderDate }}</van-col
      >
      <van-col
        span="5"
        style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135)"
        >审核日期</van-col
      >
      <van-col
        span="18"
        style="text-align:right;margin-top:0.5rem;font-size:0.9rem"
      >
        {{ !checkDate ? "" : checkDate }}</van-col
      >
      <van-col
        v-if="picItemsData.length > 0"
        span="5"
        style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135)"
        >产品工艺图</van-col
      >
      <van-col
        v-if="picItemsData.length > 0"
        span="18"
        style="text-align:right;margin-top:0.5rem;font-size:0.9rem"
      >
        <van-image
          width="50"
          height="50"
          v-for="(item, index) in picItemsData"
          :key="index"
          :src="item.value"
          @click="showes(item.value)"
        />
      </van-col>
    </van-row>
    <van-row class="title"> </van-row>
    <div v-for="(item, index) in list" :key="index">
      <van-row
        class="titles"
        :style="
          item.executorUserId != 0
            ? 'background-color: rgb(240, 249, 235);color: rgb(103, 194, 58)'
            : 'background-color: rgb(254, 240, 240);color:rgb(249, 85, 85)'
        "
      >
        <van-col
          span="8"
          style="padding:2%;display: flex;align-items: left;justify-content: left;"
          >{{ item.technologyName }}</van-col
        >
        <van-col
          span="4"
          style="padding:3%;display: flex;align-items: center;justify-content: center;"
          >{{ item.executorUserName }}</van-col
        >
        <van-col
          span="12"
          style="padding:3%;display: flex;align-items: center;justify-content: center;"
          >{{ item.executorDateTime }}</van-col
        >
      </van-row>
      <div v-if="item.children">
        <van-row
          class="titles"
          v-for="(item1, index1) in item.children"
          :key="index1"
          :style="
            item1.executorUserId != 0
              ? 'background-color: rgb(240, 249, 235);color: rgb(103, 194, 58)'
              : 'background-color: rgb(254, 240, 240);color:rgb(249, 85, 85)'
          "
        >
          <van-col
            span="8"
            style="padding:2%;display: flex;align-items: left;justify-content: left;"
            >{{ `&nbsp;&nbsp;&nbsp;` + item1.technologyName }}</van-col
          >
          <van-col
            span="4"
            style="padding:3%;display: flex;align-items: center;justify-content: center;"
            >{{ item1.executorUserName }}</van-col
          >
          <van-col
            span="12"
            style="padding:3%;display: flex;align-items: center;justify-content: center;"
            >{{ item1.executorDateTime }}</van-col
          >
        </van-row>
      </div>
    </div>
    <van-row
      class="titles"
      v-for="(item, index) in form.scanTrees"
      :key="index"
      :style="
        item.executorUserId != 0
          ? 'background-color: rgb(240, 249, 235);color: rgb(103, 194, 58)'
          : 'background-color: rgb(254, 240, 240);color:rgb(249, 85, 85)'
      "
    >
      <van-col
        span="8"
        style="padding:2%;display: flex;align-items: left;justify-content: left;"
        >{{
          item.parentId == 0
            ? item.technologyName
            : `&nbsp;&nbsp;&nbsp;` + item.technologyName
        }}</van-col
      >
      <van-col
        span="4"
        style="padding:3%;display: flex;align-items: center;justify-content: center;"
        >{{ item.executorUserName }}</van-col
      >
      <van-col
        span="12"
        style="padding:3%;display: flex;align-items: center;justify-content: center;"
        >{{ item.executorDateTime }}</van-col
      >
    </van-row>
    <div v-if="shows" class="show">
      <div
        style="widt:100%;height:100%;  display: flex;align-items: center;justify-content: center;position: relative;z-index:1"
      >
        <div style="width:100%;height:5%;position: fixed;top:0">
          <div
            @click="noShow"
            style="color:#FFF;text-align: right;font-size:1.5rem;margin-right:10px;margin-top:5px"
          >
            X
          </div>
        </div>

        <div style="position: absolute;z-index:9999999;width:97%">
          <img :src="image" style="width:100%;" />
        </div>
      </div>
    </div>
    <div style="float:left;width:100%;height:4rem"></div>
    <van-popup :show="cell" :close-on-click-overlay="false" round>
      <div
        style="width:22rem;height:23rem;padding:10px;position: relative;overflow: hidden;overflow-y: scroll;z-index:-1"
      >
        <div class="content" style="position: relative;z-index:-1">
          <div v-for="item in notices" :key="item.id">
            <div>{{ item.title }}</div>
            <div @click="clickImg($event)" v-html="item.content"></div>
          </div>
        </div>
        <!-- <div v-if="showImg" class="imgLook" @click="closeImg">
          <img :src="imgSrc" style="width: 65%" />
        </div> -->
      </div>
      <div style="widt:100%;height:3.5rem"></div>
      <div
        style="text-align: center;padding: 20rpx;position: absolute;bottom:0;height:2.5rem;width:100%;border-top:1px solid silver;line-height: 2.5rem;z-index:1000"
        :style="time > 0 ? 'color:silver' : ''"
        @click="time > 0 ? '' : (cell = false)"
      >
        我知道了{{ time > 0 ? "(" + time + ")" : "" }}
      </div>
    </van-popup>
    <van-overlay
      style="z-index:10000;display: flex;align-items: center;justify-content: center;"
      :show="showImg"
      @click="showImg = false"
    >
      <img :src="imgSrc" style="width: 65%" />
    </van-overlay>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import { Image as VanImage } from "vant";
import { Row, Col } from "vant";
import { Field } from "vant";
import { Dialog } from "vant";

// import { ImagePreview } from "vant";
import { ImagePreview } from "vant";
import { doScan, chageToken, getScanTipsState } from "@/config/_api";
import { NoticePageQuery, SystemConfigGetConfig } from "@/config/_api";
import { router } from "@/router";
import Refresh from "../refresh/index.vue";
import { GetWorkloadDisplay } from "@/config/_api";
// import { Image } from "vant";
export default {
  inject: ["reload"],
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Col.name]: Col,
    [Field.name]: Field,
    [ImagePreview.Component.name]: ImagePreview.Component,
    Refresh,
  },
  async created() {
    if (localStorage.getItem("announcement") == 1) {
      this.getNotice();
      localStorage.setItem("announcement", 2);
    }

    if (this.$route.query) {
      // { deviceid, devicename, versioncode, number }
      let row = this.$route.query;
      if (row.deviceid) {
        localStorage.setItem("deviceId", row.deviceid);
        localStorage.setItem("deviceName", row.devicename);
        localStorage.setItem("versioncode", row.versioncode);
        if (row.number) {
          this.textOrderNumber = row.number;
        }
      }
    }
    if (!this.textOrderNumber) {
      await this.Cha();
    } else {
      await this.scanOrder();
    }
    // if (!sessionStorage.getItem("type")) {
    this.scanTipsState();
    // }
    // if (!sessionStorage.getItem("alertType")) {
    this.workloadDisplay();
    // }
  },
  data() {
    return {
      showImg: false,
      imgSrc: "",
      notices: [],
      time: 0,
      cell: false,
      productionMain: true,
      shows: false,
      image: "",
      active: 0,
      show: false,
      numberOrder: "",
      msg: "",
      form: {
        scanTrees: [],
      },
      textOrderNumber: "",
      orderNumber: "",
      scanTips: "",
      sellRemark: "",
      actualAmount: 0,
      customerName: "",
      processInfo: "",
      fold: true,
      listLoading: false,
      orderDate: "",
      checkDate: "",
      list: [],
      labels: "",
      picItemsData: [],
      custName: "",
      alertType: "",
      type: false,
    };
  },

  methods: {
    closeImg() {
      console.log(11111);
      this.cell = false;
    },
    async getNotice() {
      const res = await NoticePageQuery();
      console.log(res, "12312312312");
      if (res.data.code == 1) {
        if (res.data.data.length) {
          this.cell = true;
          const ress = await SystemConfigGetConfig();
          if (ress.data.code == 1) {
            res.data.data.forEach((item) => {
              item.content = item.content.replace(
                "/\img/g",
                'img style="width:70%"'
              );
            });
            let times = 0;
            ress.data.data.forEach((item) => {
              if (item.orderIndex == 20) {
                times = item.name;
              }
            });
            this.time = times;
            let timees = setInterval(() => {
              this.time = this.time - 1;
              if (this.time < 1) {
                clearInterval(timees);
              }
            }, 1000);

            this.notices = res.data.data;
          }
        }
      }

      console.log(this.notices);
    },
    clickImg(event) {
      if (event.target.localName === "img") {
        this.imgSrc = event.target.currentSrc;
        console.log(this.imgSrc, "img");
        this.showImg = true;
      }
    },
    async workloadDisplay() {
      let { data } = await GetWorkloadDisplay();
      if (data.code) {
        this.type = data.data;
        sessionStorage.setItem("type", data.data);
      }
    },
    async scanTipsState() {
      let { data } = await getScanTipsState();
      if (data.code) {
        this.alertType = data.data;
        sessionStorage.setItem("alertType", data.data);
      }
    },
    noShow() {
      this.image = "";
      this.shows = false;
    },
    yesShow() {
      let url = this.image;
      this.image = url;
      this.shows = true;
    },
    showes(url) {
      this.image = url;
      this.shows = true;
    },
    onChange(index) {
      this.index = index;
    },
    async Cha() {
      let { data } = await chageToken({ deviceType: 3 });
      if (data && data.code == 1) {
        let { token, userInfo } = data.data;
        localStorage.setItem("token", token);
        localStorage.setItem("userData", JSON.stringify(userInfo));
        // localStorage.setItem("announcement", 1);
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        router.push("login");
      }
    },
    GetTreeData(source, id, parentId, children, pid) {
      if (!source || !source.length) return [];
      id = !id ? "id" : id;
      parentId = !parentId ? "parentId" : parentId;
      children = !children ? "children" : children;
      pid = !pid ? 0 : pid;
      let cloneData = JSON.parse(JSON.stringify(source)).filter(
        (e) => (e[id] || e[id] == 0) && (e[parentId] || e[parentId] == 0)
      );
      return cloneData.filter((father) => {
        let branchArr = cloneData.filter(
          (child) => father[id] == child[parentId]
        );
        branchArr.length > 0 ? (father[children] = branchArr) : "";
        return father[parentId] == pid; // 如果第一层不是parentId=0，请自行修改
      });
    },
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
    success() {
      var url =
        "https://ytmerp.oss-cn-shenzhen.aliyuncs.com/Portal/Audio/ScanSuccess.mp3";
      var n = new Audio(url);
      n.src = url;
      n.play();
    },
    fail() {
      var url =
        "https://ytmerp.oss-cn-shenzhen.aliyuncs.com/Portal/Audio/ScanFail.mp3";
      var n = new Audio(url);
      n.src = url;
      n.play();
    },
    repeat() {
      var url =
        "https://ytmerp.oss-cn-shenzhen.aliyuncs.com/Portal/Audio/ScanRepeat.mp3";
      var n = new Audio(url);
      n.src = url;
      n.play();
    },
    getDay(date1, date2) {
      return Math.floor((date1 - date2) / 1000);
    },
    async scanOrder() {
      if (this.textOrderNumber != this.orderNumber) {
        this.scanTips = "";
        this.orderNumber = "";
        this.sellRemark = "";
        this.customerName = "";
        this.actualAmount = "";
        this.processInfo = "";
        this.custName = "";
        this.labels = "";
        this.list = [];
      }
      this.listLoading = true;
      let res = await doScan({
        number: this.textOrderNumber,
      });
      // let timer = new Date();

      // if (sessionStorage.getItem("timer")) {
      //   let timers = this.getDay(
      //     timer.getTime(),
      //     Number(sessionStorage.getItem("timer"))
      //   );
      //   console.log(timers, "timers");
      //   if (timers <= 3) {
      //     return;
      //   } else {
      //     sessionStorage.setItem("timer", timer.getTime());
      //   }
      // } else {
      //   sessionStorage.setItem("timer", timer.getTime());
      // }
      let data = res.data;
      if (data.data) {
        this.scanTips = data.data.scanTips;
        this.orderNumber = data.data.orderNumber;
        this.sellRemark = data.data.sellRemark;
        this.customerName = data.data.customerName;
        this.actualAmount = data.data.actualAmount;
        this.processInfo = data.data.processInfo;
        this.orderDate = data.data.orderDate;
        this.checkDate = data.data.checkDate;
        this.custName = data.data.custName;
        this.list = this.GetTreeData(data.data.scanTrees, "technologyId");
        console.log(data);
        if (data.data.picItems) {
          this.picItemsData = data.data.picItems;
        }
        if (data.data.labels) {
          this.labels = data.data.labels;
        }
        if (data.data.firstScan) {
          let wages = Number(data.data.wages);
          let workload = Number(data.data.workload);
          localStorage.setItem("wages", wages.toFixed(2));
          localStorage.setItem("workload", workload);
          this.$refs["refresh"].getItemFun();
        }
        this.listLoading = false;
        if (data.code != 1) {
          this.scanTips = data.msg;
          if (data.data.scanTips) this.scanTips += ";" + data.data.scanTips;
          this.fail();
          return Dialog.alert({
            title: "扫描失败",
            message: `${data.msg}`,
          });
        } else {
          if (data.data.isSuccScan) {
            if (data.data.firstScan) {
              this.success();
              if (this.alertType) {
                return Dialog.alert({
                  title: "扫描成功",
                  message: `${data.msg}`,
                });
              }
            } else {
              this.repeat();
              if (this.alertType) {
                return Dialog.alert({
                  title: "重复扫描成功",
                  message: `${data.msg}`,
                });
              }
            }
          } else {
            this.fail();
            return Dialog.alert({
              title: "扫描失败",
              message: `${data.msg}`,
            });
          }
        }
      } else {
        if (data.msg) {
          this.scanTips = data.msg;
          this.listLoading = false;
          this.fail();
          return Dialog.alert({
            title: "扫描失败",
            message: `${data.msg}`,
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.imgLook {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(44, 45, 46);
  background: rgba(24, 24, 24, 0.5);
}
.content {
  /* overflow-y: scroll; */
  text-align: center;
  height: calc(100vh - 130px);
}
.show {
  width: 100%;
  height: 100vh;
  background-color: #00000082;
  position: fixed;
  top: 0;

  z-index: 100000;
}
.tag {
  -webkit-text-size-adjust: 100%;
  font-family: PingFang SC, Arial, Microsoft YaHei, sans-serif;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-size: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2.5px;
  white-space: nowrap;
  color: #fff;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  margin: 0;
  box-sizing: border-box;
  outline: none !important;
  background-color: rgb(215, 38, 38);
  border-color: rgb(215, 38, 38);
}
.title {
  margin-top: 1rem;
}
.titles {
  width: 95%;
  margin: auto;
  text-align: left;
  font-weight: 500;
  border-top: 0px solid;
  font-size: 14px;
}
.text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  color: #62bc7c;
}
</style>
